/* You can add global styles to this file, and also import other style files */
@import "~@ng-select/ng-select/themes/default.theme.css";
.content-header-left {

}

.content-header-right {
    text-align: right !important;
}
.ng-dropdown-panel {
    background-color: #fff;
    position: relative !important;
    /* border: 1px solid #ccc; */
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
    left: 0
}

.pimgedit{
    max-width: 300px;
}

.dx-datagrid-content .dx-datagrid-table .dx-row > td,
.dx-datagrid-content .dx-datagrid-table .dx-row > tr > td {
  vertical-align: middle;
  padding: 15px 0 15px 20px;
  border: 0px !important;
}

.dx-datagrid-headers {
    color: #ffffff;
    font-weight: 400;
    border: 0px !important;
    background-color: #78426c !important;
}

.dx-datagrid-borders > .dx-datagrid-headers,
.dx-datagrid-borders > .dx-datagrid-rowsview,
.dx-datagrid-borders > .dx-datagrid-total-footer {
  border-left: 0px !important;
  border-right: 0px !important;
}
.dx-datagrid-borders > .dx-datagrid-rowsview,
.dx-datagrid-borders > .dx-datagrid-total-footer {
  border-bottom: 0px !important;
}
